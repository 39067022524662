import React, { useEffect, useState } from 'react'
import propTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import Spinner from '../../shared/spinner'
import LoginMagicLinkForm from './login-magic-link'
import PasswordReset from './forgot-password-form'
import EmailPasswordLogin from './email-password-form'
import { clearAuth, clearPasswordlessAuth } from '../../../actions/auth.actions'
import { updateAccountInfo } from '../../../actions/account.actions'
import DefaultSeo from '../../shared/default-seo'
import LoginFooter from './login-footer'
import { Button, Box } from '@mui/material'
import { onDesktop } from '../../../styles/theme-proven/responsive'
import ProvenIcon from '../../shared/proven-icon'

const loginContainerStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  flex: 1,
  px: 2,
  pt: 3,
  pb: 5,
  minWidth: 360,
  maxWidth: 'unset',
  [onDesktop]: {
    width: '50vw',
    minWidth: 600,
    maxWidth: 'unset',
    px: 4,
    pt: 4
  }
}

const DATA_CY_PREFIX = 'login-slider'

function Login({
  resetPassword,
  passwordResetRequired: passwordResetRequiredProp,
  emailPasswordRequest: emailPasswordRequestProp,
  email: emailProp,
  isFromQuiz,
  redirect,
  nextCheckoutStep,
  onClose
}) {
  const dispatch = useDispatch()
  const { loading, error, errorUsername } = useSelector(state => state.auth)
  const { openLoginSlide } = useSelector(state => state.checkout)
  const [passwordReset, setPasswordReset] = useState(resetPassword)
  const [passwordResetRequired, setPasswordResetRequired] = useState(
    passwordResetRequiredProp || false
  )
  const [emailPasswordRequest, setEmailPasswordRequest] = useState(emailPasswordRequestProp)
  const [email, setEmail] = useState(emailProp)
  const [passwordResetEmailSent, setPasswordResetEmailSent] = useState(false)

  useEffect(() => {
    if (isFromQuiz) {
      setEmailPasswordRequest(false)
      setPasswordReset(false)
      setPasswordResetEmailSent(false)
    }
    if (typeof openLoginSlide === 'object' && openLoginSlide !== null) {
      if (openLoginSlide.view === 1) {
        togglePasswordReset()
      } else if (openLoginSlide.view === 2) {
        toggleEmailPasswordRequest()
      }
    }
  }, [])

  useEffect(() => {
    if (error === 'RESET_REQUIRED') {
      togglePasswordResetRequired()
    }
  }, [error])

  const clearAllAuthErrors = () => {
    dispatch(clearAuth())
    dispatch(clearPasswordlessAuth())
  }

  const togglePasswordReset = (e, email) => {
    if (e) {
      e.preventDefault()
      e.stopPropagation()
    }
    setPasswordReset(prev => !prev)
    setPasswordResetEmailSent(false)
    if (email) setEmail(email)
    clearAllAuthErrors()
  }

  const togglePasswordResetRequired = () => {
    setPasswordResetRequired(prev => !prev)
  }

  const togglePasswordResetEmailSent = () => {
    setPasswordResetEmailSent(prev => !prev)
  }

  const toggleEmailPasswordRequest = (e, email) => {
    if (e) {
      e.preventDefault()
      e.stopPropagation()
    }
    setEmailPasswordRequest(prev => !prev)
    if (email) {
      setEmail(email)
    }

    clearAllAuthErrors()
  }

  const handleCloseClick = () => {
    clearAllAuthErrors()
    dispatch(updateAccountInfo({ isMagicLinkSent: false }))
    const closeFunc = passwordReset
      ? togglePasswordReset
      : emailPasswordRequest
      ? toggleEmailPasswordRequest
      : onClose
    closeFunc()
  }

  const backBtnText =
    typeof openLoginSlide === 'object' && openLoginSlide.navBtnTxt
      ? openLoginSlide.navBtnTxt
      : passwordReset || emailPasswordRequest
      ? 'Back'
      : 'Close'
  const backBtnIcon = backBtnText === 'Back' ? 'arrow-left' : 'cross-close'

  const handleCloseFunc =
    typeof openLoginSlide === 'object' && openLoginSlide.closeFunc
      ? openLoginSlide.closeFunc
      : handleCloseClick

  return (
    <Box sx={{ ...loginContainerStyle }} data-cy={DATA_CY_PREFIX}>
      <DefaultSeo seoTitle="Account login | PROVEN" />
      {loading && <Spinner />}

      <Button
        variant="text"
        size="medium"
        onClick={handleCloseFunc}
        sx={{
          alignSelf: 'flex-start',
          px: 1,
          py: 0,
          height: 'auto',
          mb: 2,
          [onDesktop]: { height: 'auto' }
        }}
        startIcon={<ProvenIcon type="system" name={backBtnIcon} />}
        data-cy={`${DATA_CY_PREFIX}-close-back-button`}
      >
        {backBtnText}
      </Button>

      {passwordReset && !emailPasswordRequest && (
        <PasswordReset
          onLogin={togglePasswordReset}
          email={email}
          onPasswordResetEmailSent={togglePasswordResetEmailSent}
          passwordResetEmailSent={passwordResetEmailSent}
        />
      )}

      {passwordResetRequired && !emailPasswordRequest && (
        <PasswordReset
          onLogin={togglePasswordReset}
          email={errorUsername}
          title="Please check your email to reset password"
          subtitle="Our security system has been upgraded since your last login! For the security of your account, we have sent an email to reset your password."
          onPasswordResetEmailSent={togglePasswordResetEmailSent}
          passwordResetEmailSent={passwordResetEmailSent}
          passwordResetRequired={passwordResetRequired}
        />
      )}

      {emailPasswordRequest && (
        <EmailPasswordLogin
          email={email}
          onToggleEmailPassword={toggleEmailPasswordRequest}
          onResetPassword={() => {
            setPasswordResetEmailSent(true)
            setPasswordResetRequired(true)
            setEmailPasswordRequest(undefined)
          }}
          isFromQuiz={isFromQuiz}
        />
      )}

      {!emailPasswordRequest &&
        !passwordReset &&
        !passwordResetEmailSent &&
        !passwordResetRequired && (
          <LoginMagicLinkForm
            label="Email - Password"
            redirect={redirect}
            email={email}
            onForgotPassword={togglePasswordReset}
            onToggleEmailPassword={toggleEmailPasswordRequest}
            nextCheckoutStep={nextCheckoutStep}
            displayOnlySocialLogin={passwordResetRequired}
          />
        )}

      {!emailPasswordRequest &&
        !passwordReset &&
        !passwordResetEmailSent &&
        !passwordResetRequired && <LoginFooter closeLoginForm={onClose} />}
    </Box>
  )
}

Login.propTypes = {
  onClose: propTypes.func,
  isFromQuiz: propTypes.bool,
  redirect: propTypes.string,
  loading: propTypes.bool,
  landing_version: propTypes.string,
  nextCheckoutStep: propTypes.number,
  resetPassword: propTypes.bool,
  email: propTypes.string,
  emailPasswordRequest: propTypes.bool,
  clearAuth: propTypes.func.isRequired,
  clearPasswordlessAuth: propTypes.func.isRequired,
  error: propTypes.string,
  errorUsername: propTypes.string,
  passwordResetRequired: propTypes.bool,
  openLoginSlide: propTypes.oneOfType([propTypes.bool, propTypes.object])
}

Login.defaultProps = {
  nextCheckoutStep: 0,
  resetPassword: false,
  email: '',
  emailPasswordRequest: false,
  passwordResetRequired: false
}

export default Login
